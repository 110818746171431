import { router } from '@/router';
import { alert } from '@/stores/alert';
import { defineStore } from 'pinia';

import authRequest, { type AuthRequestResponse } from '@/requests/auth';

interface LoggedAccount {
  sub: string;
  username: string;
  email: string;
  token: string;
  name: string;
  babyName: string;
  colicsQuiz: number | null;
  is_admin: boolean;
  impersonate: string | null;
  lastLoginAt: Date | null;
  mandatoryConsent: boolean;
}

const loggedAccountInitialState: LoggedAccount = {
  sub: '',
  username: '',
  email: '',
  token: '',
  name: '',
  babyName: '',
  colicsQuiz: null,
  is_admin: false,
  impersonate: null,
  lastLoginAt: null,
  mandatoryConsent: false
};

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): { account: LoggedAccount } => ({ account: loggedAccountInitialState }),
  getters: {
    accountIsLoggedIn(state) {
      return state.account.email.length > 0;
    }
  },
  actions: {
    _assignResponseToAccount(responseAccount: AuthRequestResponse ) {
      this.account = {
        sub: responseAccount.sub,
        username: responseAccount.username,
        email: responseAccount.email,
        token: responseAccount.token,
        name: responseAccount.name || "",
        babyName: responseAccount.baby_name || "Your baby",
        colicsQuiz: responseAccount.colics_quiz,
        is_admin: responseAccount.is_admin,
        impersonate: responseAccount.impersonate,
        lastLoginAt: responseAccount.last_login_at,
        mandatoryConsent: responseAccount.mandatory_consent
      };
    },
    async login(username: string, password: string, device?: string) {
      try {
        const responseAccount = await authRequest.login(username, password, device);

        this._assignResponseToAccount(responseAccount);

        router.push({ name: 'Home' });

        alert.show('Successfully logged in', 'success');
      } catch (error) {
        console.error(error);
        this.account = { ...loggedAccountInitialState };
        alert.show('Invalid username or password');

        return error;
      }
    },
    async sessionLogin() {
      // This is called by the router to retrieve the logged user info when a session cookie is present.
      // The router will handle an error case (such as session deleted on the server) by redirecting to the login page.
      const responseAccount = await authRequest.sessionLogin();

      this._assignResponseToAccount(responseAccount);
    },
    async tokenLogin(token: string, device?: string) {
      // This is called by the router to retrieve the logged user info when a session cookie is present.
      // The router will handle an error case (such as session deleted on the server) by redirecting to the login page.
      const responseAccount = await authRequest.tokenLogin(token, device);

      this._assignResponseToAccount(responseAccount);

      router.push({ name: 'Home' });
      alert.show('Successfully logged in', 'success');
    },
    async logout() {
      await authRequest.logout();

      this.account = { ...loggedAccountInitialState };

      router.push({ name: 'Login' });
    },
    async reload(updatedAccount: AuthRequestResponse) {
      this._assignResponseToAccount(updatedAccount);
    }
  }
});
