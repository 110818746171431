import type { RouteLocationNormalized } from 'vue-router';

const MainRoutes = {
  path: '/',
  meta: { requiresAuth: true },
  redirect: '/',
  component: () => import('@/layouts/full/FullLayout.vue'),
  children: [
    {
      path: '/',
      redirect: "/home"
    },
    {
      name: 'Home',
      path: '/home',
      component: () => import('@/views/dashboards/overview/Overview.vue')
    },
    {
      name: 'Statistics',
      path: '/statistics',
      component: () => import('@/views/dashboards/statistics/Statistics.vue')
    },
    {
      name: 'Speech Development',
      path: '/speech-development',
      component: () => import('@/views/dashboards/speechDevelopment/SpeechDevelopment.vue')
    },
    {
      name: 'ChatView',
      path: '/chat',
      component: () => import('@/views/apps/chat/ChatView.vue'),
      props: (route: RouteLocationNormalized) => ({ initialMessage: route.query.message as string | undefined })
    },
    {
      name: 'Posts',
      path: '/blog',
      component: () => import('@/views/apps/blog/BlogView.vue')
    },
    {
      name: 'BlogPostView',
      path: '/blog/:id',
      component: () => import('@/views/apps/blog/BlogPostView.vue')
    },
    {
      name: 'Account',
      path: '/account',
      component: () => import('@/views/apps/account/AccountView.vue')
    },
    {
      name: 'Contact',
      path: '/contact',
      component: () => import('@/views/apps/contact/ContactView.vue')
    },
    {
      name: 'notFoundRedirect',
      path: '/:pathMatch(.*)*',
      redirect: '/home'
    }
  ]
};

export default MainRoutes;
