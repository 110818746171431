import type { RouteLocationNormalized } from 'vue-router';

const AuthRoutes = {
  path: '/auth',
  component: () => import('@/layouts/blank/BlankLayout.vue'),
  meta: { requiresAuth: false },
  children: [
    {
      name: 'Login',
      path: '/auth/login',
      component: () => import('@/views/authentication/AuthView.vue')
    },

    // When logging in by code, redirect to the home page passing the code as a query parameter.
    // The router will then try to use it as one of the options when checking if the user is logged in already
    // in the router's beforeEach hook.
    {
      name: 'Code Login',
      path: '/auth/code/:code',
      redirect: (to: RouteLocationNormalized) => {
        return { path: '/home', query: { code: to.params.code } };
      }
    },
    {
      name: 'Boxed Login',
      path: '/auth/login2',
      component: () => import('@/views/authentication/BoxedLogin.vue')
    },
    {
      name: 'Side Register',
      path: '/auth/register',
      component: () => import('@/views/authentication/SideRegister.vue')
    },
    {
      name: 'Boxed Register',
      path: '/auth/register2',
      component: () => import('@/views/authentication/BoxedRegister.vue')
    },
    {
      name: 'Side Forgot Password',
      path: '/auth/forgot-password',
      component: () => import('@/views/authentication/SideForgotPassword.vue')
    },
    {
      name: 'Boxed Forgot Password',
      path: '/auth/forgot-password2',
      component: () => import('@/views/authentication/BoxedForgotPassword.vue')
    },
    {
      name: 'Side Two Steps',
      path: '/auth/two-step',
      component: () => import('@/views/authentication/SideTwoStep.vue')
    },
    {
      name: 'Boxed Two Steps',
      path: '/auth/two-step2',
      component: () => import('@/views/authentication/BoxedTwoStep.vue')
    },
    {
      name: 'Error',
      path: '/auth/404',
      component: () => import('@/views/authentication/Error.vue')
    },
    {
      name: 'Maintenance',
      path: '/auth/maintenance',
      component: () => import('@/views/authentication/Maintenance.vue')
    }
  ]
};

export default AuthRoutes;
