import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import { useAuthStore } from '@/stores/auth';
import { useCookies } from 'vue3-cookies';
import { alert } from '@/stores/alert';


export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    MainRoutes,
    AuthRoutes
  ]
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.path == "/auth/login") {
    return next();
  }

  if (!authStore.accountIsLoggedIn) {
    const token = to.query.token?.toString();
    const code = to.query.code?.toString();
    const credentials = token || code;
    const { cookies } = useCookies();

    if (credentials !== undefined) {
      console.log("Logging in by token or code");
      const device = to.query.device as string;
      try {
        await authStore.tokenLogin(credentials, device);
      } catch (error) {
        if (code) {
          alert.show('Invalid or expired link code. Please login with email and password.');
        } else {
          alert.show('Invalid or missing login token. Please login with email and password.');
        }
        console.log("Session login failed, redirecting to login page");

        return next('/auth/login');
      }

      return next();
    } else if (cookies.get('connect.sid')) {
      console.log("Logging in by session cookie");
      try {
        await authStore.sessionLogin();

        // If user not given the mandatory consent, redirect to home page - there is a dialog to read and accept that
        if(!authStore.account.mandatoryConsent) {
          next('/home');
        } else {
          next();
        }

      } catch (error) {
        console.log("Session login failed, redirecting to login page");

        return next('/auth/login');
      }

      return next();
    } else {
      console.log("No login credentials, redirecting to login page");

      return next('/auth/login');
    }
  } else {
    return next();
  }
});
